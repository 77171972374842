



























import pdfjsLib from 'pdfjs-dist/webpack'
import { Component, Vue, Prop } from 'vue-property-decorator'
import SmallLoader from '@/components/loaders/SmallLoader.vue'
import { MultipleDocumentsService } from '@/services/multipleDocuments'
import { ImplementationResource } from '@/models/implementations/ImplementationResource'
import { stateModule } from '@/store'

@Component({
  components: {
    SmallLoader
  }
})
export default class ImplementationDetailDocument extends Vue {

  private multipleDocumentsService: MultipleDocumentsService = new MultipleDocumentsService({ project_id: 0 })

  @Prop()
  private implementation!: ImplementationResource
  public msalToken: any = null
  private viewer: any = null
  private isRenderingDocument: boolean = false
  private isLoadingPdf: boolean = false

  private mounted(): void {
    this.setToken()
  }
  
  private async setToken() {
    await this.getMsalToken()
    this.openPdfViewer()    
  }

  public async download() {
    this.isLoadingPdf = true
    try {
      const data: any = await this.multipleDocumentsService.download(this.implementation.pdf_link)

      this.isLoadingPdf = false
      const filename = this.implementation.name + '.pdf';
        const pdfFile = new Blob([data]);
        const downloadLink = document.createElement("a");
        downloadLink.download = filename;
        downloadLink.href = window.URL.createObjectURL(pdfFile);
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
    }
    catch (error) {
      this.isLoadingPdf = false
    }
  }

  private async getMsalToken() {
    const token = await this.$msal.acquireToken()

    if (token) {
        this.msalToken = token.idToken.rawIdToken
    }
  }

  private async openPdfViewer(): Promise<void> {
    if (!this.implementation.pdf_link) return
    try {
      const viewerlib = require('pdfjs-dist/web/pdf_viewer')

      this.viewer = new viewerlib.PDFViewer({
        container: this.$refs.article as HTMLElement,
      })

      this.isRenderingDocument = true
      const doc = await pdfjsLib.getDocument({
        url: this.implementation.pdf_link,
        withCredentials: true,
        httpHeaders: {
          Authorization: 'Bearer ' + this.msalToken
        }
      }).promise

      this.viewer.setDocument(doc)
    } catch {
      stateModule.setNotification({
        message: 'Something went wrong while rendering this document.',
        type: 'error'
      })
    } finally {
      this.isRenderingDocument = false
    }

  }

}
